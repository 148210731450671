import Question from './Content/Question';
import Options from './Content/Options';
import Solution from './Content/Solution';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Swal from "sweetalert2";
import '../../css/pdf.css';
import ShortAnswer from './ShortAnswer'
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingComponent from "../../loading.component";
// import LoadingComponent from "../components/loading.component";


function PDFBody({ contentFontSize, showCorrectOption, discussionSpacing, showShortAnswer, showSolution,showQuestions, showOptions, showBillingual, showPreviousYearTag,settestDetail}) {
    const location = useLocation();
    let navigate = useNavigate();
    const teacher = JSON.parse(localStorage.getItem('teacher')) || {};
    const [pdfContent, setPDFState] = useState([]);
    let configData = location.state?.data;

    const initMathJax = () => {
        window.MathJax = {
            tex: {
                inlineMath: [['$$', '$$'], ['\\(', '\\)']] 
            },
        };

        (function () {
            var script = document.createElement('script');
            script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js';
            script.async = true;
            document.head.appendChild(script);
        })();
    }

    // https://teach-backend.bigbooster.in/utk/getSetQuestion/:id
    const fetchDataUtakrsh = async () => {
        try {
            const response = await fetch(`https://teachbackendapi.utkarshpublications.com/utk/getSetQuestion/${configData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            if(data.Status){
                const dataRes=transformData(data.data);
                settestDetail(data.set_detail);

                setPDFState(dataRes)
                initMathJax()
            }else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                });

                Toast.fire({
                    icon: "error",
                    title: "No data found",
                });
                return navigate(location.state?.path || "/")//prev
            }

        } catch (error) {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: "error",
                title: "Something went Wrong -1",
            });
            return navigate(location.state?.path || "/")//prev
            console.error('Error:', error.message);
        }
    };

    // console.log(configData)

    const transformData = (questions) => {
        return questions.map((question1, index) => {
            question1.question_no=index+1;
            
            if(question1.answer==1){
                question1.answer="A";
            }else if(question1.answer==2){
                question1.answer="B";
            }else if(question1.answer==3){
                question1.answer="C";
            }else if(question1.answer==4){
                question1.answer="D";
            }

            question1.ppt_position_config=JSON.stringify({
                question_height: "846px",
                question_width: "743px",
                question_x_position: 516,
                question_y_position: 0,
                question_font_size: "40px",
                option_height: "193px",
                option_width: "417px",
                option_x_position: 820.666748046875,
                option_y_position: 397,
                option_font_size: "40px",
            });
            return question1;
        });
    }; 
    // data format
    // console.log(transformedData)

    useEffect(() => {
        initMathJax();
    }, [showSolution, showBillingual]);

    const getDataInLoop = async (url, offset) => {
        let options = {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer keyrPNFKcIz6BrXFR`
            },
        };
        const response = await fetch(url + "&offset=" + offset, options);
        const resData = await response.json();
        for (let index = 0; index < resData.records.length; index++) {
            let element = resData.records[index];
            resData.records[index] = { ...element, ...element.fields };
        }
        setPDFState((prev) => {
            return [...prev, ...resData.records]
        });

        if ((resData?.offset || "") != "") {
            await getDataInLoop(url, resData?.offset);
        }
    };

    const pdfDataLoad = async () => {
        if (location.state.data.endpoint) {
            let options = {
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer keyrPNFKcIz6BrXFR`
                },
            };
            let resData = [];
            try {
                const response = await fetch(location.state.data.endpoint, options);
                resData = await response.json();

            } catch (e) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                });

                Toast.fire({
                    icon: "error",
                    title: "Something went Wrong",
                });
                return navigate(location.state?.path || "/")//prev
            }

            let offset = resData?.offset || "";

            for (let index = 0; index < resData.records.length; index++) {
                let element = resData.records[index];
                resData.records[index] = { ...element, ...element.fields };
            }

            if (resData["status"] === 404) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                });

                Toast.fire({
                    icon: "error",
                    title: "No data found",
                });
                return navigate(location.state?.path || "/")//prev
            }

            setPDFState(resData["records"]);
            if (offset != "") {
                await getDataInLoop(location.state.data.endpoint, offset);
            }
            initMathJax();

        } else {
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ set_id: configData.set_id, set_password: configData.set_password, endpoint: "" }),
            };
            const response = await fetch(`https://teachbackendapi.utkarshpublications.com/app-to-web`,
                options
            );
            const resData = await response.json();

            if (resData["status"] === 404) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                });

                Toast.fire({
                    icon: "error",
                    title: "No data found",
                });
                return navigate(location.state?.path || "/")//prev
            }

            if (resData['data']) {
                const excludesString = ["testbook.com", "googleapi.com"];
                for (const obj of resData['data']) {
                    const propertiesToCheck = [
                        'question_hin',
                        'question_eng',
                        'option1_eng',
                        'option2_eng',
                        'option3_eng',
                        'option4_eng',
                        'option5_eng',
                        'option1_hin',
                        'option2_hin',
                        'option3_hin',
                        'option4_hin',
                        'option5_hin',
                        'paragraph_text'
                    ];
                    const isExcluded = propertiesToCheck.some((property) => {
                        const value = obj[property];
                        if (Array.isArray(value)) {
                            return value.some((item) =>
                                excludesString.includes(String(item))
                            );
                        }
                        return excludesString.includes(String(value));
                    });

                    if (isExcluded) {
                        propertiesToCheck.forEach((property) => {
                            obj[property] = "This question is preparing";
                        });
                    }
                }
            }

            setPDFState(resData["data"]);
            initMathJax();
        }
    }


    let initialized = false
    useEffect(() => {
        // console.log(location.state)

    }, [])

    // main validation function
    useEffect(() => {
        if (configData && !Array.isArray(configData) && typeof configData !== 'object') {
            fetchDataUtakrsh();
        }else{
            if (!initialized) {
                initialized = true
                pdfDataLoad();
            }
            if (!location.state) {
                navigate("/")
            }
        }
        // debugger
    }, []);


    if (pdfContent.length === 0)
        return (
            <LoadingComponent />
        );
    return (
        <>
            <div className="content bg-base-100" contentEditable={true} suppressContentEditableWarning={true}>

                <div className="watermark">
                    <img style={{ borderRadius: "800px",opacity: "0.2"}} id="company-logo" src={teacher.logo_url || "images/logo.png"} alt="logo" width="600px" height="600px" />
                </div>
                {    
                    pdfContent.map((itm, index) => {
                        return (<>
                            <Question
                                record_id={itm.record_id}
                                question_no={itm.question_no}
                                previous_of={itm.previous_of}
                                showPreviousYearTag={showPreviousYearTag}
                                // setShowPreviousYearTag={setShowPreviousYearTag}
                                //question_hin={itm.question_hin ? parse(itm.question_hin.replaceAll(`\\′`, `'`)) : "---"}
                                question_hin={showQuestions==false || itm.question_hin == null || itm.question_hin == undefined ? false : showBillingual!="English"? parse(itm.question_hin.replaceAll(`\\′`, `'`)) : false}
                                question_eng={showQuestions==false || itm.question_eng == null || itm.question_eng == undefined ? false : showBillingual!="Hindi"? parse(itm.question_eng.replaceAll(`\\′`, `'`)) : false}
                                fontSize={contentFontSize}
                                paragraph_text={itm.paragraph_text==null?'':parse(itm.paragraph_text.replaceAll(`\\′`, `'`))}
                            />

                            {
                                (itm.option1_hin != "" && itm.option2_hin != "" && itm.option3_hin != "" && itm.option4_hin != "") ? showOptions ? <Options
                                    question_no={itm.question_no}
                                    option1_hin={itm.option1_hin ? (showBillingual!="English"?itm.option1_hin.replaceAll(`\\′`, `'`): "") : ""}
                                    option2_hin={itm.option2_hin ? (showBillingual!="English"?itm.option2_hin.replaceAll(`\\′`, `'`) : "") : ""}
                                    option3_hin={itm.option3_hin ? (showBillingual!="English"?itm.option3_hin.replaceAll(`\\′`, `'`) : "") : ""}
                                    option4_hin={itm.option4_hin ? (showBillingual!="English"?itm.option4_hin.replaceAll(`\\′`, `'`) : "") : ""}
                                    option5_hin={(itm.option5_hin == null || itm.option5_hin == undefined) ? "" : (showBillingual!="English"?itm.option5_hin.replaceAll(`\\′`, `'`):"")}

                                    option1_eng={itm.option1_eng == null || itm.option1_eng == undefined ? "" : (showBillingual!="Hindi" ? itm.option1_eng.replaceAll(`\\′`, `'`) : "")}
                                    option2_eng={itm.option2_eng == null || itm.option2_eng == undefined ? "" : (showBillingual!="Hindi" ? itm.option2_eng.replaceAll(`\\′`, `'`) : "")}
                                    option3_eng={itm.option3_eng == null || itm.option3_eng == undefined ? "" : (showBillingual!="Hindi" ? itm.option3_eng.replaceAll(`\\′`, `'`) : "")}
                                    option4_eng={itm.option4_eng == null || itm.option4_eng == undefined ? "" : (showBillingual!="Hindi" ? itm.option4_eng.replaceAll(`\\′`, `'`) : "")}
                                    option5_eng={(itm.option5_eng == null || itm.option5_eng == undefined) ? "" : (showBillingual!="Hindi"?itm.option5_eng.replaceAll(`\\′`, `'`):"")}
                                    Answer={showCorrectOption ? itm.answer : 0}
                                    fontSize={contentFontSize}
                                /> : false: false
                            }


                            {(itm.solution_hin == null || itm.solution_hin == undefined || itm.solution_hin == "") ? false : showSolution ? showBillingual!="English"?<Solution fontSize={contentFontSize} solution_hin={parse(itm.solution_hin)} /> : false: false}
                            {(itm.solution_eng == null || itm.solution_eng == undefined || itm.solution_eng == "") ? false : showSolution ? showBillingual!="Hindi" ? <Solution fontSize={contentFontSize} solution_eng={parse(itm.solution_eng)} /> : false : false}
                            <div className="special-arrangement" style={{ minHeight: discussionSpacing + "px", backgroundColor: "transparent" }} contentEditable={true}></div>
                        </>)
                    })
                }
            </div>

            {showShortAnswer ? <ShortAnswer answer={pdfContent} /> : false}
        </>
    );
}


function SettingModalPopup(props) {
    return (
        <>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel" style={fontStyle}>PDF Configuration</h5>
                        </div>
                        <div className="modal-body">
                            <div className="fontSize" style={{ display: "flex", justifyContent: "space-between" }}>
                                <label htmlFor="customRange1" className="form-label" style={fontStyle}>Content Font Size: {props.fontSize}</label>
                                <input min="10" max="18" step="0.5" type="range" className="form-range" id="customRange1" style={{ width: "50%" }} value={props.fontSize}
                                    onChange={(e) => props.setContentFontSize(e.target.value)} />
                            </div>
                            <div className="discussionSpacing" style={{ display: "flex", justifyContent: "space-between" }}>
                                <label htmlFor="customRange2" className="form-label" style={fontStyle}>Discussion Spacing: {props.currentDiscussionSpacing}</label>
                                <input min="0" max="70" step="0.1" type="range" className="form-range" id="customRange2" style={{ width: "50%" }} value={props.discussionSpacing}
                                    onChange={(e) => props.setDiscussionSpacing(e.target.value)} />
                            </div>
                            <div className="AnswerToggle" style={{ display: "flex", justifyContent: "space-between" }}>
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault" style={fontStyle}>Answer Bold</label>
                                <input onClick={() => {
                                    props.setShowCorrectOption(!props.showCorrectOptionCurrentState)
                                }} style={{ backgroundColor: "cyan" }} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                            </div>
                            <hr />
                            <div>
                                <div className="visibilityCheckbox">
                                    <div>
                                        <input className="form-check-input" type="checkbox" value="" id="showAnswerWidget" onClick={() => {
                                            props.setShowShortAnswer(!props.showShortAnswer)
                                        }} />
                                        <label className="form-check-label" htmlFor="showAnswerWidget">
                                            Short Answer Widget
                                        </label>
                                    </div>

                                    <div>
                                        <input className="form-check-input" type="checkbox" value="" id="hideQuestion" onClick={() => {
                                            props.setShowQuestions(!props.showQuestions)
                                        }} />
                                        <label className="form-check-label" htmlFor="hideQuestion">
                                            Hide Question
                                        </label>
                                    </div>
                                    
                                    <div>
                                        <input className="form-check-input" type="checkbox" value="" id="hideOption" onClick={() => {
                                            props.setShowOptions(!props.showOptions)
                                        }} />
                                        <label className="form-check-label" htmlFor="hideOption">
                                            Hide Option
                                        </label>
                                    </div>

                                    <div>
                                        <input className="form-check-input" type="checkbox" value="" id="showSolution" onClick={() => {
                                            props.setShowSolution(!props.showSolution)
                                        }} />
                                        <label className="form-check-label" htmlFor="showSolution">
                                            Show Solution
                                        </label>
                                    </div>
                                    <div>
                                        <input className="form-check-input" type="checkbox" value={props.showBillingual} id="showBillingual"  onClick={(e) => props.setShowBillingual(e.target.value)}/>
                                        <label className="form-check-label" htmlFor="showBillingual">
                                            Billingual PDF
                                        </label>
                                    </div>
                                    <div>
                                        <input className="form-check-input" type="checkbox" value="" id="showPreviousYearTag" onClick={() => {
                                            props.setShowPreviousYearTag(!props.showPreviousYearTag)
                                        }} />
                                        <label className="form-check-label" htmlFor="showPreviousYearTag">
                                            Hide Previous Year Tag
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const fontStyle = {
    fontFamily: "Noto Sans",
    fontWeight: "bold"
};

export default PDFBody;
